import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Explore from "../components/explore"

import yepmed from "../images/yepmed.png"
import crossdev from "../images/crossdev.png"
import restartmed from "../images/restartmed.png"

const CoopPage = () => (
  <div id="indexPage">
    <Header/>
    <div className="content-pad">
      <h2>Sinergies with ENI CBC MED projects</h2>
      <p>
        One of the fundamental principles that the FISH MED NET project strives on is creating a
        cooperative environment among the various stakeholders, by providing the necessary
        visibility of the involved stakeholders to third parties. All of these efforts aim for a better world
        to live in and similarly the project also seeks out synergies with other ENI CBC MED projects
        who share similar goals. In this page it is possible to view the different projects that have
        synergies with FISH MED NET and learn more about what UN SDGs are shared with each
        project.
      </p>

      <div className="coop">
        <a href="https://www.enicbcmed.eu/projects/yep-med"><img src={yepmed}/></a>
        <p>
          The <b>Youth Employment in the Ports of the Mediterranean</b> project aims to increase the involvement of youth by upskilling the level of port-logistics, increase local employment opportunities with job placements and strengthening the role of SME’s operating in the port ecosystems. The project is aims in creating a collaborative national and transnational partnerships between stakeholders involved in the port-logistics throughout the Mediterranean basin.
        </p>
      </div>
      <hr/>

      <div className="coop">
        <a href="https://www.hiddenmediterranean.net/en/"><img src={crossdev}/></a>
        <p>
          The <b>CROSSDEV PROJECT</b> is a project built to elevate lesser-known tourist destinations through socio-economic, local culture and their heritage throughout the Mediterranean. The project has developed a collaboration among countries by enhancing sustainable tourism policies and practices with the aim of ameliorating the livelihood of the stakeholders involved.
        </p>
      </div>
      <hr/>

      <div className="coop">
        <a href="https://medsustainabletourism.net/"><img src={restartmed}/></a>
        <p>
          The <b>RESTART MED!</b> project contributes to the economic and social development of the Mediterranean, revitalizing the sustainable tourism economy after the pandemic through 4 key strategies: diversification, innovation, deseasoning, and local economy development.
          The project aims at reshaping the tourism demand, supporting authorities in developing sustainable tourism policies, and encouraging cooperation among tourism stakeholders.
        </p>
      </div>

      <h2>Explore between our last proposals</h2>
    </div>
    <Explore slug="index"/>

    <Footer/>
  </div>
)

export default CoopPage
